$topHeaderOffset: 90;

.about-page {
  &.with-sections {
    /* Offset topbar when clicking a#section-id */
    section::before {
      display: block;
      content: " ";
      margin-top: -$topHeaderOffset;
      height: $topHeaderOffset;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .m-info-box {
    padding: 0 0.5rem;
  }
}