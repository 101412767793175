@import '~@internetstiftelsen/styleguide/src/globals';
@import '~@internetstiftelsen/styleguide/src/configurations/extends';
@import '~@internetstiftelsen/styleguide/src/configurations/typography/typography';
@import '~@internetstiftelsen/styleguide/src/organisms/header/header';
@import '~@internetstiftelsen/styleguide/src/organisms/sections/sections';
@import '~@internetstiftelsen/styleguide/src/structures/article';
@import '~@internetstiftelsen/styleguide/src/organisms/footer/footer';
@import '~@internetstiftelsen/styleguide/src/atoms/spinner/spinner';
@import '~@internetstiftelsen/styleguide/src/atoms/skip/skip';
@import '~@internetstiftelsen/styleguide/src/molecules/alert/alert';
@import '~@internetstiftelsen/styleguide/src/molecules/card/card';
@import '~@internetstiftelsen/styleguide/src/configurations/grid/grid';
@import '~@internetstiftelsen/styleguide/src/configurations/colors/colors';
@import '~@internetstiftelsen/styleguide/src/configurations/colors/_background-colors';

.width-full {
  max-width: none !important;
}

.spinner-wrapper {
  text-align: center;
  margin: auto;
  height: 2rem;
  width: 2rem;
}

// Styleguide classes fixes:
.root {
  display: flex;
  flex: 1 0 auto;
}

.onFocus {
  & *:focus {
    box-shadow: 0 0 0 2px inset #ff4069 !important;
    outline: 0;
  }
}

.page-load-spinner {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}