.route-title {
  margin-top: 1rem;
  padding: 0 0.5rem;

  .description {
    margin-bottom: 1.75rem,
  }

  &.max-width-extended {
    max-width: 70%;
  }
}